<template>
    <div class="login-body">
        <div class="login-wrapper">
            <div class="login-panel">
                <img src="/assets/layout/images/logo-dark.svg" class="logo" alt="diamond-layout"/>
                <form @submit.prevent="handleSubmit">
                    <div class="login-form">
                        <h2>Забыли пароль?</h2>
                        <p>Нет аккаунта?
                            <router-link :to="{name : 'register'}">Регистрация</router-link>
                        </p>
                        <div class="field">
                            <span class="p-input-icon-left">
                                <i class="pi pi-envelope"/>
                                <InputText id="email" v-model="form.fields.email"
                                           type="email"
                                           placeholder="Е-майл"
                                           :area-invalid="form.errors.email ? true : null"
                                           :aria-describedby="form.errors.email ? form.errors.email : null"
                                           :class="{ 'p-invalid' : form.errors.email }"/>
                            </span>
                            <div v-if="form.errors.email" class="p-error text-left"><small>{{ form.errors.email
                                }}</small></div>
                        </div>
                        <Button label="ВОССТАНОВИТЬ" type="submit" :loading="forgotLoading"></Button>
                    </div>
                </form>
                <p>Вспомнили пароль?
                    <router-link :to="{name : 'login'}">Нажмите сюда</router-link>
                    для входа
                </p>
            </div>
            <div class="login-image">
                <div class="login-image-content">
                    <h1>Access to your</h1>
                    <h1>Diamond</h1>
                    <h1>Account</h1>
                    <h3>
                        Lorem ipsum dolor sit amet, consectetur <br/>
                        adipiscing elit. Donec posuere velit nec enim <br/>
                        sodales, nec placerat erat tincidunt.
                    </h3>
                </div>
                <div class="image-footer">
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                    <div class="icons">
                        <i class="pi pi-github"></i>
                        <i class="pi pi-twitter"></i>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { reactive, computed } from 'vue'
import ToastMessageService from '../services/ToastMessageService'
import AuthService from '../services/AuthService'
import FormService from '../services/FormService'
import router from '../router'
import { useStore } from 'vuex'

export default {
    setup () {
        const store = useStore()
        const formService = new FormService()
        const authService = new AuthService()
        const toastMessageService = new ToastMessageService()
        const defaultForm = () => {
            return {
                fields: {
                    email: null
                },
                errors: {}
            }
        }
        const form = reactive(defaultForm())

        const resetForm = () => {
            Object.assign(form, defaultForm())
        }

        const forgotLoading = computed(() => store.state.auth.forgotLoading)

        const handleSubmit = async () => {
            await authService.forgot(form.fields).then(async () => {
                resetForm()
                await router.push({ name: 'login' })
                await toastMessageService.add({
                    detail: 'Инструкция по востановлению пароля отправлена на указанный email',
                    life: 9000
                })
            }).catch(exception => formService.fillErrors(exception, form))
        }

        return {
            form, resetForm, handleSubmit, forgotLoading
        }
    }
}
</script>
